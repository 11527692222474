<template>
    <v-container fluid v-if="!loading">
        <carousel-3d class="ma-0 pa-0" :autoplayTimeout="5000" :disable3d="false" :autoplay="false" display="5" :border="0" :height="getCardHeight" :width="400" :space="300" :clickable="false" :controls-visible="true">
            <slide v-for="(slide, i) in slides" :index="i" :key="i" >
                <v-card elevation="10" class="ma-0 pa-0 rounded-xl" style="width: 100% !important; height: 100% !important;">
                    <v-card-title class="text-h4 font-weight-medium">
                        <span class="mt-4" style="font-family: Mistral">{{ slide.name }}</span>
                    </v-card-title>
                    <v-divider style="margin: auto; display: block; width: 50%;"></v-divider>
                    <v-card-text class="font-weight-medium text-caption black--text text-justify" v-html="slide.testimonial">
                    </v-card-text>
                    <v-card-actions style="bottom: 0; position: absolute; width: 100%;">
                        <v-container fluid class="text-caption">
                            <v-divider style="margin: auto; display: block; width: 50%;"></v-divider>
                            <v-container fluid style="display: flex;">
                                <div class="text-left" style="width: 50%;">
                                    <span class="text-left">{{ slide.local }}</span>
                                </div>
                                <div class="text-right" style="width: 50%;">
                                    <span class="text-right">{{ slide.data }}</span>
                                </div>
                            </v-container>
                        </v-container>
                    
                    </v-card-actions>
                </v-card>
            </slide>
    </carousel-3d>
    </v-container>
</template>
<style>
.carousel-3d-slide{
    height: 100% !important;
}
.carousel-3d-container {
  .carousel-3d-slide {
    background-color: transparent;
    padding: 20px;
    
    .title { font-size: 22px; }
  }
}

</style>
<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
    components: {
        Carousel3d,
        Slide
    }  ,
    data: () => ({
        loading: true,
        windowWidth: 0,
        slides: [
            {
                name: 'Francisca Fernandes',
                testimonial: 'Após fazer o 2º passo as mudanças foram imediatas. Passei a sentir-me mais leve, feliz e em paz. Senti a energia em redor com mais facilidade e muito amor por tudo e todos. A forma de ver a natureza mudou; se já a considerava um porto seguro, depois consegui ver e sentir a sua grandeza. As cores ficaram mais bonitas. A minha confiança ficou mais forte. Sou mais segura de mim, do que acredito. Sinto-me a vibrar e sinto que essa vibração chega aos que me rodeiam. Muito grata à Alda, ao Universo e a mim por ter a capacidade de receber esta energia tão pura, leve e repleta de AMOR.',
                local: '',
                data: ''
            },  
              {
                name: 'Francisca Fernandes',
                testimonial: 'Após fazer o 2º passo as mudanças foram imediatas. Passei a sentir-me mais leve, feliz e em paz. Senti a energia em redor com mais facilidade e muito amor por tudo e todos. A forma de ver a natureza mudou; se já a considerava um porto seguro, depois consegui ver e sentir a sua grandeza. As cores ficaram mais bonitas. A minha confiança ficou mais forte. Sou mais segura de mim, do que acredito. Sinto-me a vibrar e sinto que essa vibração chega aos que me rodeiam. Muito grata à Alda, ao Universo e a mim por ter a capacidade de receber esta energia tão pura, leve e repleta de AMOR.',
                local: '',
                data: ''
            },  
            {
                name: 'Alexandra Monteiro',
                testimonial: 'Antes do Método, eu não me reconhecia. Estava sempre para fora de mim, mas não me sentia parte de nada. Com a Sincronização, passei a reconhecer-me. Tornou-se evidente o Eu Sou e sinto-me, cada vez mais, parte do todo. O tempo deixou de existir...este é o momento. E estou sempre a vibrar.',
                local: '',
                data: ''
            },
            {
                name: 'Maria Domingas',
                testimonial: 'Ao fazer o curso do método de Sincronização encontrei definitivamente a minha essência, o meu Eu verdadeiro! Após o 2º passo, senti uma Força muito grande em mim e uma PAZ e Harmonia muito, muito grande, tudo em meu redor flui de uma forma muito leve e simples.',
                local: '',
                data: ''
            },
            {
                name: 'Guilhermina Miranda',
                testimonial: 'Eu sinto-me muito grata por estar neste processo de aprendizagem e transformação. Com o treino do Método sinto-me mais próxima de mim e dos que me rodeiam e mais consciente. Sinto mais energia, tranquilidade e sorrio mais. A realização dos exercícios nem sempre corre em pleno, mas continuo com bastante persistência...quero muito estar preparada para o próximo passo. Obrigada.',
                local: '',
                data: ''
            },
            {
                name: 'Cinha Pinto',
                testimonial: 'Desde o 1º dia que me sinto muito mais tranquila (era muito ansiosa). O meu coração está cheio de Gratidão e Amor',
                local: '',
                data: ''
            },
            {
                name: 'Dora Gonçalves',
                testimonial: 'O Método da Sincronização foi – me aplicado pela Alda e, dada a cura tão rápida, decidi fazer os cursos do Método.<br/>A experiência foi e continua a ser muito transformadora na minha vida.<br/>Antes estava a viver sem objetivos e logo no dia seguinte ao Método senti- me bem viva , com uma alegria imensa e hoje posso- vos garantir que sinto um Amor enorme por tudo e todos ; estou tranquila, feliz, durmo sem medicação, paciente com a vida e com as pessoas, sociável, aceito as diferenças dos outros com amor e viver para mim agora é uma Bênção.<br/>Agora sinto-me a Vibrar no Coração e estou sintonizada com o Universo.<br/>Agora, quando choro é de GRATIDÃO.',
                local: '',
                data: ''
            },
            {
                name: 'Bia Vaz',
                testimonial: 'Sinto que evoluí muito com o método. Agora, sou mais consciente e alinhada com a minha essência. Sou mais eu como parte do todo. Sinto-me mais capaz e mais confiante. ',
                local: '',
                data: ''
            },
            {
                name: 'Glória Mendes',
                testimonial: 'A partir dos 50 anos comecei a ter crises de ansiedade, tendo recorrido a médicos que me medicaram, mas as crises continuaram deixando-me nervosa, reservada, medrosa e insegura. Com a transmutação de energias em sincronicidade, dada nesta bonita caminhada baseada no amor e muita entrega, não tive mais crises, tornando-me uma pessoa mais calma, confiante, alegre, com muita paz e atenta a tudo que me rodeia',
                local: '',
                data: ''
            },
            {
                name: 'Cristina',
                testimonial: 'Senti que nasce em nós um sentido de missão e contribuição para um mundo melhor. ',
                local: '',
                data: ''
            },
            {
                name: 'Lúcia',
                testimonial: 'Queriada a minha partilha sobre o método e dia a dia desde este meu "regresso".<br/>Sobre a prática corre bem no sentido de vibração, uma sensação de conexão. Por vezes sinto, ao longo inquietação, outras um Amor, Gratidão, Paz, Leveza brutais.<br/>No dia a dia a conexão permanece, é muito bom, como se estivesse sempre acompanhada. Estou mais consciente para a Gratidão e Compaixão, como se um olhar novo (esclarecido) para tudo e todos. Na minha casa, família, amigos também noto diferença....penso que notam/sentem o meu regresso e encontros e reencontros (e desencontros) têm acontecido. Os momentos de tristeza e medo não "ganham" o meu dia. Quando me sinto "desnutrida" sei onde está o meu alimento. ',
                local: '',
                data: ''
            },
            {
                name: 'Artur',
                testimonial: 'Tenho andado a tentar compreender o que há em mim de novo ou diferente desde que iniciei o 2º Passo. E não é fácil dar uma resposta concreta e imediata sobre o que aconteceu em mim porque está sempre a acontecer, é um estado de consciência a alterar-se todos os dias, é um processo que não para. Parece-me importante constatar que, afinal, tudo é importante, pois tudo faz parte do mundo em que vivemos e, por isso, é parte de nós. Eu sei que que perdi medos, ganhei confiança em mim e nos outros, deixei de ser seletivo para abraçar tudo com mais compaixão, mais Amor e mais perdão. E, sinceramente, agora tudo é mais fácil e sentido. Muito grato à Alda e a todos os que contribuíram para este passo evolutivo.',
                local: '',
                data: ''
            },
            {
                name: 'Mónica Fernandes',
                testimonial: 'Eu iniciei o método e finalizei o 2°passo! Senti o poder da energia que estava presente durante o processo e que, a cada minuto aumentava ... fui inundada por uma paz incrível e, consegui pela primeira vez sentir e ouvir "coisas" que mais ninguém experienciou naquele momento, apesar de ser uma pessoa muito cética! Foi brutal!<br/> Obrigada a Alda por todo ensinamento, e pela oportunidade que me ofereceu para minha evolução! Gratidão, gratidão!',
                local: '',
                data: ''
            },
            {
                name: 'Sara',
                testimonial: 'Numa conjuntura como esta, a de união no mesmo espaço e tempo de alguém com imensa sabedoria e experiência à de um grupo de pessoas com um objetivo comum que é q vontade de se reencontrarem a si mesmos e de potenciar as suas curas, devemos estar muito atentos; pois são momentos de verdadeira transformação (nossa e da Terra). Essa transformação foi-nos oferecida porque acreditamos muito que era possível e que precisávamos dela… a nossa consciência bateu-nos à porta. Não importa em que ponto da caminhada estamos, interessa é regressar à estrada que mais nos beneficia a todos e ao planeta. Muitas emoções e sensações vêm ao de cima e muitas lágrimas também. As palavras passam a ser sentidas antes de serem ditas. Mas são ditas, amor não fica por dizer ou por expressar… ele é o motor do nosso veículo. Obrigada a todos os seres que tornaram isto possível!',
                local: '',
                data: ''
            },
            {
                name: 'Maria',
                testimonial: 'Desde de que comecei a fazer este método com a Alda que me tenho sentido uma “pessoa diferente”. Não é que tenha mudado muito, o cabelo está mais curto, os olhos não mudaram de cor, o sorriso está mais rasgado, não tenho um nariz de bruxa… por isso está tudo mais ou menos igual.<br/> Eu sempre achei que aquilo que diziam sobre o amor incondicional era uma treta (desculpem a linguagem), sempre achei que fosse uma coisa que os meus pais me diziam para me “convencerem” de que me amavam, ou era algo só existiam nos filmes da Disney. Com este método percebi que não! Isto do amor condicional é algo bem real! Parece que tenho algo a rasgar no meu peito e é tão bom!<br/>É um calor, mas que calor! Preenche-me o corpo inteiro. É algo que não consigo explicar, mas sim sentir! E que bom é sentir este calor pelo corpo e principalmente no coração!',
                local: '',
                data: ''
            },
            {
                name: 'Teresa',
                testimonial: 'Chegou o momento de partilhar as minhas experiências, resultantes de todo este processo de sincronização com novas energias. Primeiro, devo dizer que me sinto muito bem, sobretudo quando envio energia para todas as pessoas que posso e faço-o todos os dias várias vezes. Também mando energia para a natureza, muita, porque fiquei muito mais sensível ao seu estado profundamente agredido. Para mim é penoso reconhecê-lo. Também estou a gostar mais das pessoas, aceitando todas as diferenças: têm todos os direitos que eu tenho a serem como são. Quando faço a visualização das células de luz e as vejo pelo corpo todo plenas de energia, fico a desejar que isso possa acontecer a toda a gente que se cruza comigo. Também no chakra 6 sinto que algo está a mudar na minha saúde. Espero que assim seja.',
                local: '',
                data: ''
            },
            {
                name: 'Maria Domingues',
                testimonial: 'Esta minha caminhada longa e persistente que me trouxe até á Alda e até este método magnífico foi para mim como um Renascer. Em tantos anos neste caminho da Espiritualidade, encontrei algo muito forte, de grande responsabilidade e consciência. Tenho trabalhado o método quase diariamente e tornou-se algo muito natural na minha vida, que me transformou completamente num momento que muito precisava… não seria de esperar outra coisa , certo?!! Pela primeira vez este método fez-me olhar para dentro de uma forma muito introspetiva mas sem descorar a importância e responsabilidade que Eu/Nós temos para o Outro e para o Planeta!! Obrigada Alda por este Acordar',
                local: '',
                data: ''
            },
            {
                name: 'António',
                testimonial: 'Há  1 ano tive a má  notícia do meu 2° cancro muito evasivo  e sendo um cancro raro ,não havia quimio específica para ele. Alguém me indicou a Alda e  passado 1 semana fiz o MÉTODO e iniciei a minha  grande caminha.  Estou a ser tratado com  quimioterapia  e nunca houve um dia que deixasse de trabalhar, tendo eu um trabalho com alguma exigência física.<br/> Este MÉTODO equlibra me fisica e emocionalmente. <br/>Hoje acordo e deito me verdadeiramente feliz , e cada dia me sinto mais GRATO por este despertar de CONSCÊNCIA nesta minha grande transformaçã',
                local: '',
                data: ''
            },
            {
                name: 'Eduarda',
                testimonial: 'Olá amigos (as) da jornada maravilhosa que estamos a fazer e a ser conduzida pela nossa Alda. Ontem  no nosso ENCONTRO foi uma tarde cheia de luz, aprendizagem,ótima energia e convívio. Este último tb é muito importante para nos conhecermos e juntos nos sentimos ligados  ao TODO. <br/>Ando nisto há alguns anos e honestamente nunca vi nada igual. Vim feliz, acordei feliz e continuo com uma felicidade interior, difícil explicar.  Há uma coisa que eu sei, nunca perder o foco!! Um mt obgda Alda e bem haja a todos vós',
                local: '',
                data: ''
            },
            {
                name: 'Joana',
                testimonial: 'Gostava de vos dizer que entrei neste método e por conseguinte neste grupo, por necessidade de outra pessoa que por vezes sinto ser mais importante para mim do que eu própria. E tem dado resultado, de cada vez que eu venho aqui expressar gratidão é porque um pequeno” milagre” aconteceu e eu sinto uma enorme gratidão porque vejo o desenvolvimento positivo dessa pessoa.<br/>Neste momento já não é só por ele mas também por mim pois sinto uma grande serenidade, um amor pelo próximo que até agora não sentia e um confiar no universo que me sossega. Sinto que o receio que tinha tem vindo a diminuir e que a vibração  do coração está a ultrapassar a da mente.<br/>Agradeço a todos o sentir da energia que juntos conseguimos captar e à Alda por nos ensinar o caminho com tanto amor e paciência.',
                local: '',
                data: ''
            },
            {
                name: 'Luís',
                testimonial: 'A minha experiência com o método permitiu iluminar as sombras que se tornaram visíveis e claras com a prática regular do método.A chave para mim foi a prática. Foi por aqui que entrei… mas o caminho é experienciar e viver comigo e com o Outro em harmonia, em consonância com os valores Universais.<br/> A Humildade, a Gratidão ou o Amor só fazem sentido pleno do Eu para o Outro! <br/>É por aqui que estou a caminhar…',
                local: '',
                data: ''
            },
            {
                name: 'Benedita',
                testimonial: 'Considero um privilegio ter tido acesso a este metodo tão rapido e de tanta transformação. Estar neste grupo , onde se partilha de modo genuino o que sentimos, onde impera a vontade de ajudar o outro e de querer mudar o mundo para melhor, é para mim uma enorme alegria e uma benção.<br/>Sinto me sempre protegida Obrigada Alda. Obrigada a todos!',
                local: '',
                data: ''
            },
            {
                name: 'Catarina',
                testimonial: 'Para quem não sabe eu sou médica de família. E tenho uma paixão pela área da Psicologia e do comportamento humano. Talvez por isso o universo me coloque mais disponível para as pessoas me procurarem e falarem sobre coisas "do arco da velha", há dias bem difíceis. Nesses dias, não me sinto energeticamente tão afetada, tão sugada como antigamente. <br/>Consigo ficar mais leve fazendo posteriormente a transmutação também em alturas em que me sinto mais irritada, com menor tolerância (até com o pequenino), pratico o MÉTODO e faz-me regressar à base e ter mais clareza de raciocínio e maior paciência.',
                local: '',
                data: ''
            },
            {
                name: 'Domingas',
                testimonial: 'Bom Dia a Todos! Deixo o meu testemunho foi o maior ponto de viragem da minha vida. Logo após ter feito o 2° Passo, tive a maior tomada de consciência desta minha vida Espiritual. Tudo o que me rodeava mudou radicalmente e de uma forma surpreendente e muito necessária, o ambiente e a energia do meu local de trabalho mudou de uma forma que mais parecia que tinha mudado de empresa.<br/> A energia da minha casa, marido, filhos também acalmou de uma forma maravilhosa e surpreendente! Entendi claramente da mudança que se tinha dado no meu EU! Obrigada Alda',
                local: '',
                data: ''
            },
            {
                name: 'Teresa',
                testimonial: 'Chegou o momento de eu, Teresa, partilhar as minhas experiências, resultantes de todo este processo de sincronização com novas energias. Primeiro, devo dizer que me sinto muito bem, sobretudo quando envio energia para todas as pessoas que posso e faço-o todos os dias várias vezes. Também mando energia para a natureza, muita, porque fiquei muito mais sensível ao seu estado profundamente agredido.<br/> Para mim é penoso reconhecê-lo. Também estou a gostar mais das pessoas, aceitando todas as diferenças: têm todos os direitos que eu tenho a serem como são. Quando faço a visualização de luz vejo pelo corpo todo pleno de energia, fico a desejar que isso possa acontecer a toda a gente que se cruza comigo. Espero que assim seja.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'Descobri finalmente que elevei a minha gratidão pelo que sou, pelo que tenho todos os dias, pelo que sonho, e pela oportunidade de ajudar outras pessoas frequentemente; descobri também que não sou capaz de ódios, rancores ou vinganças. A minha gratidão actual ao Superior, já me permite perdoar as minhas imperfeições,  os meus erros e deslumbramentos próprios duma consciência em crescimento. Evoluir é aceitar, compreender, perdoar e amar. E eu estou muito grato à Alda, que através deste Método,  me vai ajudando todos os dias a tornar-me uma pessoa melhor.Bem hajas Alda por todo o teu empenho na ajuda para esta evolução.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'Já tendo passado por outros cursos (como outros cursos), e sendo adepta de meditações, continuava a sentir que faltava algo na minha vida, algo mais simples (porque é suposto ser mesmo assim, certo? simples!) e eficaz. Este método veio trazer-me as ferramentas que faltavam para a minha vida e que eu no fundo sempre intui que deveriam existir, para me permitir limpar energias nocivas e reequilibrar-me e para me permitir ajudar os outros! Sou eternamente grata ao Universo por ter ensinado a Alda, e sou eternamente grata à Alda e toda a equipa de luz que nos ajudam todos os dias!',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'O Método ajudou-me a transformar o caos em ordem, a dar sentido ao meu passado e a ter foco no presente, com uma visão clara do futuro. A gratidão que sinto é imensurável, pois agora Eu Sou, para mim e para os outros.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'A convite da minha querida amiga Francisca embarquei nesta “viagem” onde encontrei uma certeza: que a vida é um milagre e que quando nos dedicamos a nós e aos outros, acontecem pequenos milagres. O método passou a ser a minha ÂNCORA e as pessoas com quem partilho esta viagem são cheias de amor e de abraços… eu própria já abraço 🫂 sinto-me acolhida e compreendida e sei que o trabalho é árduo e diário para todos que estão no método mas, juntos e ainda que sejamos todos diferentes, somos UM. Obrigada à nossa Alda, que sempre nos guia e nos abraça nas dificuldades. Obrigada à Dora que de tanto coração que tem nos faz sentir “pequeninos” mas com a esperança que seremos grandes de coração. Obrigada ao grupo.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'Com a prática do Método, eu estou a aprender a conectar-me comigo, com os outros, com a Natureza e com o Universo. Esta conexão dá-me energia , alegria, serenidade e equilíbrio. Sinto os meus sentidos mais despertos e a  minha consciência a expandir.<br/> O Método ajuda-me a ter mais confiança na construção do meu caminho e mostra-me que não estou sozinha.<br/> Gratidão Alda, pelo desenvolvimento do Método e partilha do mesmo, em benefício de Todos.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'Quando iniciei este Método sentia- me  francamente cansada física e emocionalmente. Estava desmotivada e desencantada com a vida. Este Método trouxe - me mais amor próprio,  força , energia e vontade infinita de viver e de espalhar esta minha alegria com os outros. Eu , que era um pouco anti- social, sinto- me em família e em alegria com o grupo que criámos no Método.  Encontrar a minha casa fez - me sem dúvida Renascer. Obrigada Alda, por  nos fazeres crescer em Consciência e Amor. Gratidão.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: ' Estou eternamente grato a todos que fazem parte da minha vida, porque só nada teria conseguido ser o que sou actualmente.Sinto me realmente mudado na minha forma de ser.<br/>O MÉTODO ajudar-me ter consciência no que faço em mim, mas mais na relação ao Outro, maneiras de ver diferentes.Tenho a plena consciência de um longo caminho a fazer e a  construir em  relação às Pessoas e ao Planeta. Fico feliz por ver testemunhos do grupo no qual faço parte e quero continuar a fazer.<br/>Agradeço em especial à ALDA o que me tem ajudado. GRATIDÃO para  Todos',
                local: '',
                data: ''
            },
            {
                name: 'Paula Gouveia',
                testimonial: ' As adversidades da Vida é que nos fazem crescer. Eu num dos períodos mais difíceis da minha Vida tive a sorte de ter acesso a este método maravilhoso, através da querida Alda! Estarei eternamente grata ao Universo. O que tenho evoluído. O MEDO não me deixava seguir confiante. Agora sim, sinto que estou no caminho certo. Com Resiliência, AMOR e muita GRATIDÃO, todos conseguimos ser felizes e abençoados!!  ',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: ' Antes de iniciar o Método tinha uma imensa dificuldade em ligar-me emocionalmente com os outros e sentia que tinha que manter constantemente um certo “teatro” nas minhas relações sociais. Isso deixava-me exausto e levava-me a evitar socializar. Mesmo depois de tentar várias coisas, a situação não mudava, mas algo dentro de mim dizia-me que tinha de continuar a tentar qualquer coisa diferente.Depois do Método, comecei a sentir a ligação com os outros naturalmente, o que realmente me deixa grato, pois era algo que procurava à muito tempo. O Método fez também com que eu me conhece-se melhor, o que me permite encarar a vida com confiança e a alegria de estar vivo. Muito Obrigado ao Universo por tudo o que amo e por me ter dado a oportunidade de compreender tudo isto com este Método.',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: ' Ter a oportunidade de ter acesso ao método é algo que nos transforma de uma maneira tão subtil e profunda simultaneamente. Trata-se uma verdadeira revolução espiritual mesmo para os mais céticos. Sentir ‘o concreto’ dos resultados em nós próprios é a prova de que este é o caminho a seguir para o nosso bem-estar e para a Humanidade. <br/>Agradeço por ter tido esta oportunidade de conhecer o Método e de o poder aplicar na minha vida quotidiana',
                local: '',
                data: ''
            },
            {
                name: 'Testemunho',
                testimonial: 'Antes do método, acreditava que era a melhor versão de mim, que tudo o que fazia estava correcto, mas na verdade havia muita insegurança, necessidade de satisfazer, agradar o outro, de ser perfeita em tudo o que fazia, o que levava a ansiedade, medos, julgamentos sobre mim e sobre os outros, momentos de stress, pouca paciência na vida pessoal e profissional e até mesmo momentos de zanga, nem sabia bem porquê. Após o método a mudança foi imediata. Sou mais feliz, leve, plena, a minha visão do mundo e de mim mudou radicalmente. Sou uma pessoa segura de mim, no que acredito, que começo a aceitar-me com defeitos e virtudes, percebo que tenho um percurso de evolução e logo sou mais confiante. Dei por mim a ver com outros olhos o que me rodeia, tanto as diferentes situações do dia-a-dia como as pessoas. Há cada vez mais uma ligação com o Todo, com as pessoas, com a Natureza, com o Universo, abri o meu coração, julgo menos e ajudo mais, procurando ser mais disponível para o outro e conseguindo ver o outro como um Ser em aprendizagem. Os meus dias passaram a ser vividos tendo como base a Gratidão e o Amor, e sinto que esta vibração se alastra para quem me rodeia e não só. Agradeço o que fui, o que sou e o que serei. Muito GRATA',
                local: '',
                data: ''
            },
        ]
    }),
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
        this.loading = false
    },
    beforeDestroy() { 
        window.removeEventListener('resize', this.onResize); 
    },
    methods:{
        onResize() {
            this.windowWidth = window.innerWidth
            console.log(this.windowWidth)
        }
    },
    computed:{
        getCardHeight(){
            if(this.windowWidth == 0){
                return '500px'
            }

            if(this.windowWidth >= 500){
                return '500px'
            }

            console.log(this.windowWidth)

            let sizeHeight = (this.windowWidth + 100)

            if(this.windowWidth >= 400 && this.windowWidth <= 450)
                sizeHeight = this.windowWidth + 150

            if(this.windowWidth > 400 && this.windowWidth <= 430)
                sizeHeight = this.windowWidth + 170

            if(this.windowWidth > 400 && this.windowWidth <= 420)
                sizeHeight = this.windowWidth + 190

            if(this.windowWidth > 400 && this.windowWidth <= 410)
                sizeHeight = this.windowWidth + 330

            if(this.windowWidth > 300 && this.windowWidth <= 400)
                sizeHeight = this.windowWidth + 400
            
            if(this.windowWidth > 300 && this.windowWidth <= 380)
                sizeHeight = this.windowWidth + 500

            if(this.windowWidth > 300 && this.windowWidth <= 370)
                sizeHeight = this.windowWidth + 600

            if(this.windowWidth > 300 && this.windowWidth <= 360)
                sizeHeight = this.windowWidth + 700

            if(this.windowWidth > 300 && this.windowWidth <= 350)
                sizeHeight = this.windowWidth + 800

            if(this.windowWidth > 300 && this.windowWidth <= 340)
                sizeHeight = this.windowWidth + 900

      
            if(this.windowWidth > 300 && this.windowWidth <= 330)
                sizeHeight = this.windowWidth + 1000

            if(this.windowWidth > 300 && this.windowWidth <= 320)
                sizeHeight = this.windowWidth + 1100

            if(this.windowWidth > 300 && this.windowWidth <= 310)
                sizeHeight = this.windowWidth + 1200

            if(this.windowWidth > 200 && this.windowWidth <= 300)
                sizeHeight = this.windowWidth + 1300

            if(this.windowWidth <= 290)
                sizeHeight = this.windowWidth + 1400

            if(this.windowWidth <= 280)
                sizeHeight = this.windowWidth + 1500
            
            if(this.windowWidth <= 270)
                sizeHeight = this.windowWidth + 1600

            if(this.windowWidth <= 260)
                sizeHeight = this.windowWidth + 1700
            
            if(this.windowWidth <= 250)
                sizeHeight = this.windowWidth + 1800
            
            if(this.windowWidth <= 240)
                sizeHeight = this.windowWidth + 2600

            if(this.windowWidth <= 230)
                sizeHeight = this.windowWidth + 3200

            if(this.windowWidth <= 220)
                sizeHeight = this.windowWidth + 3800

            if(this.windowWidth <= 210)
                sizeHeight = this.windowWidth + 4300

            if(this.windowWidth <= 200)
                sizeHeight = this.windowWidth + 4900
            return sizeHeight + 'px';
        }
    }
}
</script>